
import { computed, defineComponent, ref } from 'vue'
import BoardLayout from '@/components/templates/BoardLayout.vue'
import { useStore } from '@/store'
export default defineComponent({
  components: {
    BoardLayout
  },
  setup() {
    const store = useStore()
    const totalClients = computed(() => store.getters.activeClients.length)
    const tabs = computed(() => [
      { routerName: 'ClientsListings', routerText: 'Active (' + totalClients.value + ')' },
      { routerName: 'ArchiveClients', routerText: 'Archive' }
    ])
    return { tabs }
  }
})
